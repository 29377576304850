@import '../../styles/abstract/variables';

.container {
  background: $color-primary-dark-blue;
  height: 80px;
}

.languageText {
  font-family: 'SuisseIntl-Regular', sans-serif;
  font-size: 16px;
  color: white;
}

p {
  font-family: 'SuisseIntl-Regular', sans-serif;
  font-size: 16px;
}

.innerWrapper {
  padding-top: 22px;
  padding-bottom: 22px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.profileWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.name {
  margin-left: 12px;
  font-size: 16px;
  font-family: 'SuisseIntl-Medium', sans-serif;
  color: white;
}

.image {
  display: block;
}
