@font-face {
  font-family: 'ArgestaDisplay-Regular';
  src: local('ArgestaDisplay-Regular'),
    url('../../assets/fonts/ArgestaDisplay-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'SuisseIntl-Regular';
  src: local('SuisseIntl-Regular'),
    url('../../assets/fonts/SuisseIntl-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'SuisseIntl-Medium';
  src: local('SuisseIntl-Medium'),
    url('../../assets/fonts/SuisseIntl-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'SuisseIntl-Bold';
  src: local('SuisseIntl-Bold'),
    url('../../assets/fonts/SuisseIntl-Bold.otf') format('opentype');
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'ArgestaDisplay-Regular', sans-serif;
  font-size: 16px;
  scroll-behavior: smooth;
  -webkit-text-size-adjust: none;
  min-height: 100vh;
  line-height: 1.5;
}
