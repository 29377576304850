/* COLORS */

$color-primary-dark-blue: #001b2b;
$color-primary-dark-blue-opacity: rgba(0, 27, 43, 0.3); //#001B2B
$color-primary-dark-blue-medium-opacity: rgba(0, 27, 43, 0.4); // #001b2b
$color-primary-light-blue: #2ca8d4;
$color-primary-yellow: #fff200;
$color-primary-yellow-opacity: rgba(255, 242, 0, 0.2); //#fff200
$color-title: #00131e;
$color-border-event-box: #dddcf9;
$color-form-subtitle: #0e6e7c;
$color-form-label: rgba(19, 20, 21, 0.3); //#131415
$color-form-label-active: #007b84;
$color-form-submit-button-disabled: #314422;
$color-border-light-blue: rgba(0, 27, 43, 0.2); //#001b2b
$color-form-error-text: #bd3547;
$color-input-placeholder: #696974;
$color-dark-grey: #2f3435;
$color-light-grey: #8f9ead;
$color-border-light-gray: #f7f7f7;
$color-border-grey: #e7e7e7;
$color-border-dark-grey: #cdcdcf;
$color-status-red-background: #ffe2e2;
$color-status-red-text: #ff4d67;
$color-status-green-background: #c2fcfb;
$color-status-green-text: #009999;
$color-status-blue-text: #073ee8;
$color-status-blue-background: #deedff;
$color-form-background: #f2f0e9;
$color-warning: #bd3547;
$color-login-background: #f2f0e9;

/* SHADOWS */

$box-shadow-black: 0 12px 24px -4px rgba(0, 0, 0, 0.26);

/* SCREENS */

$screen-tablet-landscape: 61.93rem; // 991px
$screen-mobile-landscape: 46.25rem; // 740px
$screen-mobile-portrait-lg: 30rem; // 480px
